import React from 'react'
import Img from 'gatsby-image'
import { truncate, stripHtml } from '../helpers/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTwitter,
  faFacebookF,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import {
  CardColumns,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  CardSubtitle,
} from 'reactstrap'
import { Link as GatsbyLink } from "gatsby"
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Masonry from 'react-masonry-css'

const Link = ({ children, to, className, activeClassName, partiallyActive }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        className={className}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} target="_blank" className={className}>
      {children}
    </a>
  )
}


class NewsBlocks extends React.Component {
  render() {
    const { items } = this.props
    const breakpointColumnsObj = {
      default: 4,
      992: 4,
      576: 2,
      400: 2,
    }

    return (
      <div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {items.map(({ node }, i) => {
            
            let link = null;

            switch(node.type) {
              case 'News':
                link = `/news/${node.slug}`
              break;
              case 'Twitter':
                link = node.externalUrl
              break;
            }

            return (
              <div key={`nb${i}`}>
              <Link className='news-item-link-wrapper' aria-label='Read news article' to={link}>
              <Card
                
                className={`news-item news-item--${node.type.toLowerCase()} news-item--bg${i %
                  5}`}
              >
                {node.heroImage !== null && (
                  <Img fluid={node.heroImage.fluid} />
                )}
                <CardBody>
                  <span className='news-item__date'>{node.publishDate}</span>

                  {node.body !== null && node.type !== 'News' && (
                    <CardTitle>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: truncate(
                            stripHtml(node.body.childMarkdownRemark.html),
                            50
                          ),
                        }}
                      ></span>
                    </CardTitle>
                  )}
                  {node.title !== null && node.type === 'News' && (
                      <CardTitle>{truncate(node.title)}</CardTitle>
                  )}
                  <CardText>
                    {node.type === 'News' && (
                      <span>
                        Read more{' '}
                        <FontAwesomeIcon
                          className="small-chevron"
                          icon={faChevronRight}
                        />
                      </span>
                    )}

                    {node.type === 'Twitter' && (
                      <span>
                        <FontAwesomeIcon icon={faTwitter} /> @CleanAirGM
                        </span>
                    )}
                    {node.type === 'Facebook' && (
                      <span>
                        <FontAwesomeIcon icon={faFacebookF} /> @CleanAirGM
                      </span>
                    )}
                    {node.type === 'Instagram' && (
                      <span>
                        <FontAwesomeIcon icon={faInstagram} /> @CleanAirGM
                      </span>
                    )}
                  </CardText>
                </CardBody>
              </Card>
              </Link>
              </div>
            )
          })}
        </Masonry>
      </div>
    )
  }
}

export default NewsBlocks
