
export const truncate = (str, max = 10) => {
  const array = str.trim().split(' ')
  const ellipsis = array.length > max ? '...' : ''

  return array.slice(0, max).join(' ') + ellipsis
}

export const stripHtml = (html) => {
  html = html.replace(/<p>/g, '{nl}')
  html = html.replace(/<[^>]+>/g, '')
  html = html.replace(/{nl}/g, '<br />')
  // replace first occurrence
  html = html.replace('<br />', '')
  html = html.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
  return html
}


