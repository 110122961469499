import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import header from '../images/CleanAir-Desktop-Header-1920x340-Curve-News-2x.png'
import headerMobile from '../images/CleanAir-Mobile-Header-1080x550-Curve-News-2x-1.png'
import { Container, Row, Col, Button } from 'reactstrap'

import wave from '../images/wave.png'
import NewsBlocks from '../components/news-blocks'

class NewsIndex extends React.Component {
  shuffle(array) {
    let counter = array.length

    // While there are elements in the array
    while (counter > 0) {
      // Pick a random index
      let index = Math.floor(Math.random() * counter)

      // Decrease counter by 1
      counter--

      // And swap the last element with it
      let temp = array[counter]
      array[counter] = array[index]
      array[index] = temp
    }

    return array
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const items = get(this, 'props.data.news.edges')
    // let tweets = get(this, 'props.data.twitter.edges')
    // tweets = tweets.filter(t => !t.node.body.childMarkdownRemark.html.includes('RT '))

    // let newsItems = []
    // items.map((n, i) => {
    //     newsItems.push(n)
    //     if (i%3 === 0 || i%5 === 0) {
    //       if (tweets.length > 0) {
    //       newsItems.push(tweets.shift())
    //       }
    //     }
    // })


    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={'News | ' + siteTitle} />
          <div className="page-banner page-banner--disclaimer">
            <img className="d-none d-lg-block" src={header} alt="" />
            <img className="d-block d-lg-none" src={headerMobile} alt="" />{' '}
            <h1>News</h1>
          </div>
        </div>

        <Container className="mt-5 mb-5">
          <NewsBlocks items={items} />
        </Container>
      </Layout>
    )
  }
}

export default NewsIndex

export const pageQuery = graphql`
  query NewsQuery {
    site {
      siteMetadata {
        title
      }
    }
    news: allContentfulNewsItem(
      sort: { fields: [publishDate], order: DESC }
      filter: { type: { eq: "News" } }
      limit: 40
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(maxWidth: 400, maxHeight: 400) {
              ...GatsbyContentfulFluid
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          type
          externalUrl
        }
      }
    }
    twitter: allContentfulNewsItem(
      sort: { fields: [publishDate], order: DESC }
      filter: { type: { eq: "Twitter" } }
      limit: 20
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(maxWidth: 400, maxHeight: 400) {
              ...GatsbyContentfulFluid
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          type
          externalUrl
        }
      }
    }
  }
`
